.bg-dark {
  background-color: #161616 !important;
}
.bg-transparent-dark {
  background-color: rgba(0, 0, 0, 0.377);
}
.navbar-main {
  position: fixed;
  width: 100%;
  height: 65px;
  z-index: 999;
  padding: 15px 30px;
}

.social-links {
  opacity: 0.7;
}

.social-links:hover {
  opacity: 1;
}

@media only screen and (max-width: 800px) {
  .navbar-main {
    height: 58px;
    padding: 8px 20px;
  }
  .navbar-toggler {
    padding: .20rem .50rem;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
}
