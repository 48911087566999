.side-navigation {
  position: fixed;
  top: 300px;
  z-index: 998;
}
.landing-video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-top: 0px;
}
.Landing-logo img {
  width: 800px;
}

.landing-center-data {
  text-align: center;
  color: white;
  margin-top: 140px;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  margin: auto;
  width: 660px;
  margin-top: 210px;
}
.description p {
  margin-top: 25px;
  margin-bottom: 25px;
}

.landing-animation-down {
  transition-timing-function: ease-in;
  transition: 1s;
  transform: translateY(60%);
}
.landing-animation-up {
  transition-timing-function: ease-in;
  transition: 1s;
  transform: translateY(-34%);
}

.landing-btn {
  background-color: rgba(0, 0, 0, 0.274);
  border: 1.5px solid white;
  color: white;
  margin-right: 10px;
  margin-bottom: 25px;
  padding: 10px 45px 10px 45px;
  transition: 0.5s;
  font-weight: bolder;
}

.landing-center-data button:hover {
  background-color: rgba(199, 212, 23, 0.397);
  padding: 15px 55px 15px 55px;
  color: black;
  border: none;
}

.Scroll-down-Arrow {
  padding-top: 30px;
}
.Scroll-down-Arrow span {
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
  transform: rotate(45deg);
  /*  animation: animate 3s infinite ease-out;*/
  animation: animate 3s ease infinite;
}

@keyframes animate {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 30px;
  }
  100% {
    margin-top: 0px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

.Nav-Up-Arrow span {
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
  transform: rotate(225deg);
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 50px;
}

.Nav-down-Arrow span {
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
  transform: rotate(45deg);
  margin-top: 15px;
  cursor: pointer;
  margin-left: 50px;
}

@media only screen and (max-width: 1580px) {
  .side-navigation {
    top: 280px;
  }
  .Landing-logo img {
    width: 620px;
  }
  .landing-center-data {
    margin-top: 100px;
  }
  .content {
    width: 600px;
    margin-top: 180px;
    font-size: 0.92rem;
  }
  .description p {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .landing-animation-down {
    transform: translateY(60%);
  }
  .landing-animation-up {
    transform: translateY(-36%);
  }
}

@media only screen and (max-width: 800px) {
  .side-navigation {
    display: none;
  }
  .Landing-logo img {
    width: 520px;
  }
  .landing-center-data {
    margin-top: 130px;
  }
  .content {
    width: 560px;
    margin-top: 140px;
    font-size: 0.95rem;
  }
  .description p {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .Scroll-down-Arrow {
    padding-top: 80px;
  }

  .landing-animation-down {
    transform: translateY(60%);
  }
  .landing-animation-up {
    transform: translateY(-36%);
  }
  .Scroll-down-Arrow {
    padding-top: 0px;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 480px) {
  .Landing-logo img {
    width: 290px;
  }
  .landing-center-data {
    margin-top: 125px;
  }
  .content {
    width: 310px;
    margin-top: 150px;
    font-size: 0.88rem;
  }
  .landing-btn {
    border: 1.1px solid white;
    margin: 25px 10px 25px 0px;
    padding: 8px 22px 8px 22px;
    font-size: 0.85rem;
  }
  .description p {
    margin-top: 0px;
    margin-bottom: 0px;
  } 

  .Scroll-down-Arrow {
    padding-top: 0px;
    margin-top: -50px;
  }

  .landing-animation-down {
    transform: translateY(60%);
  }
  .landing-animation-up {
    transform: translateY(-36%);
  }
} 
 
@media only screen and (max-width: 330px) {
  .Landing-logo img {
    width: 250px;
  }
  .landing-center-data {
    margin-top: 85px;
  }
  .content {
    width: 250px;
    margin-top: 150px;
    font-size: 0.80rem;
  }
  .landing-btn {
    margin: 15px 5px 15px 0px;
    padding: 7px 16px 7px 16px;
    font-size: 0.80rem;
  }
  .Scroll-down-Arrow {
    padding-top: 0px;
    margin-top: -60px;
  }

  .landing-animation-down {
    transform: translateY(60%);
  }
  .landing-animation-up {
    transform: translateY(-36%);
  }
}


:root {
  --video-width: 100vw;
  --video-height: 100vh;
}
@media (min-aspect-ratio: 16/9) {
  :root { --video-height: 56.25vw; }
}
@media (max-aspect-ratio: 16/9) {
  :root { --video-width: 177.78vh; }
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}