body {
  font-family: "Open Sans", sans-serif;
}

.sideNav {
  position: fixed !important;
  top: 0 !important;
  z-index: 999 !important;

}

.sidenavButton {
  transition: 0.2s ease-in;
}

.fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-auto-height.fp-section, .fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section {
  height: auto!important;
}

/* .footer{
  height: 100px;
} */
