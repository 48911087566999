.Main-screenShots {
  height: 100vh;
  background-color: black;
}

.ss {
  /* height: 100vh;
    width: 200%;
    height: 200%;

    margin-left: 100px;
    margin-left: 0px ; */
}

.scene {
  width: 100%;
  overflow: hidden;
}


.carousel-control-prev{
  /* width: 20px;
  height: 20px;
  margin-top: 45.6%; 
   margin-left: 40%;  */
  opacity: 1;
  position: static;

}

.carousel-control-prev span {
  

  position: absolute;
  bottom: 0;
  left: 600px;


}

.carousel-control-next {

  opacity: 1;
  position: static;
}
.carousel-control-next span {
  position: absolute;
  bottom: 0;
  right: 600px;


}


.carousel-indicators {
  width: fit-content;
  margin: auto;
}

@media (max-width: 1200px) {

  
  

.carousel-control-prev span {
  

  position: absolute;
  left: 80px;


}

.carousel-control-next span {
  position: absolute;
  right: 80px;

}

  
} 

/* 
@media(max-width:768){
    .ss{

        width: 500px;
        height: 500px;
        object-fit: cover;

        margin-left: 100px;
        margin-left: 0px ;
    }

    .scene{
        width: 100%;
        overflow: visible;

    }


    .carousel-control-prev{
        width: 20px;
        height: 200px;
         margin-top: 60%; 
        margin-left: 200px;
        background-color: greenyellow;
    }



} */
