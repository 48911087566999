.footer-main {
  background-color: #1b1b19;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 10rem 6rem;
}

.footer-container {
  width: 80%;
  height: 85%;
  margin: auto;
}

.footer-row1 {
  display: flex;
  justify-content: space-between;
}
.footer-row2 {
  display: flex;
  /* justify-content: space-between; */
  /* background-color: yellow; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* align-items: center;     */
  justify-content: flex-end;
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;

  border: 0;
  border-top: 1px solid #eee;
  border-color: #333 !important;
}

.footer-row3 {
  width: 50%;
}

.footer-row3 span {
  color: #ccc;
  box-sizing: border-box;
  font-size: 0.71em;
  font-family: OpenSans, sans-serif;
}

.footer-row4 {
  /* background-color: chocolate; */
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 4%;
}

.left-items {
  margin-left: -30px;
  padding-top: 25px;
}

.left-items li {
  display: inline;
  padding-right: 25px;
}

.right-items li {
  display: inline;
}
.right-items img {
  height: 36px;
  width: 36px;
  opacity: 0.9;
}
.right-items img:hover {
  opacity: 1;
}

.footer-row4 a {
  color: #ccc;
  box-sizing: border-box;
  font-size: 0.71em;
  font-family: OpenSans, sans-serif;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}
ul {
  list-style-type: none;
}
.footer-row4 a:hover {
  color: #f2c82d !important;
  text-decoration: underline;
}

.footer-socialbtns {
  align-items: center;
}

.footer-socialbtns img {
  margin-right: 12px;
  height: 30px;
  width: 32px;
  transition: 0.5s;
  filter: grayscale(1);
  cursor: pointer;
}
.footer-socialbtns img:hover {
  filter: grayscale(0);
}
.footer-socialbtns a:hover {
  text-decoration: underline;
}

.scrolltoTopbtn img {
  height: 33px;
  width: 33px;
  transition: 0.5s;
  cursor: pointer;
  filter: grayscale(1);
}

.scrolltoTopbtn img:hover {
  filter: grayscale(0);
}

.arrowIcon {
  height: 50px !important;
  width: 50px !important;
}

@media (max-width: 1200px) {
  .footer-row3 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .footer-container {
    width: 100%;
    height: 100%;
  }
  .footer-socialbtns img {
    margin-right: 12px;
    height: 26px;
    width: 26px;
  }
  .arrowIcon {
    height: 30px !important;
    width: 30px !important;
  }
  .footer-row2 img {
    width: 120px;
  }
}
@media (max-width: 500px) {
  .footer-main {
    padding: 8rem 4rem;
  }
  .footer-container {
    width: 100%;
    height: 100%;
  }
  .footer-socialbtns img {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
  .arrowIcon {
    height: 25px !important;
    width: 25px !important;
  }
  .footer-row2 img {
    width: 100px;
  }
  .right-items img {
    height: 25px;
    width: 25px;
  }
  .left-items {
    margin-left: -38px;
    padding-top: 0px;
  }
  .left-items ul {
    width: 230px;
  }
  .left-items li {
    padding-right: 10px;
  }
}
@media (max-width: 420px) {
  .footer-main {
    padding: 9rem 4rem;
  }
  .right-items img {
    height: 22px;
    width: 22px;
  }
  .left-items {
    margin-left: -40px;
  }
  .left-items ul {
    width: 228px;
  }
  .left-items li {
    padding-right: 8px;
  }
}
@media (max-width: 380px) {
  .left-items ul {
    width: 215px;
  }
  .left-items li {
    padding-right: 3px;
  }
}
@media (max-width: 330px) {
  .footer-main {
    padding: 6.2rem 3.4rem;
  }
  .left-items ul {
    width: 170px;
  }
  .left-items li {
    padding-right: 3px;
  }
}
