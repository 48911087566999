.Mooding-main {
  height: 100vh;
  background: url("../../assets//images/mooding.jpg");
  overflow: hidden;
}

.center-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  /* background-color: blueviolet; */
  margin: auto;
  margin-top: 32%;
  margin-left: 12%;
  max-height: 100%;
}

.mooding-animation-up {
  transition-timing-function: ease-in;
  transition: 0.9s;
  transform: translateY(-78%);
}

@media only screen and (max-width: 1580px) {
  .center-box {
    margin-top: 35%;
  }
}
@media only screen and (max-width: 1440px) {
  .center-box {
    margin-top: 40%;
  }
}
@media only screen and (max-width: 1100px) {
  .center-box {
    margin-top: 49%;
  }
  .heading {
    font-size: 1rem !important;
  }
  .details {
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .center-box {
    margin-top: 64%;
    justify-content: space-evenly !important;
  }
  .square-box-main {
    height: 136px !important;
    text-align: left !important;
    width: 30% !important;
    justify-content: space-between !important;
    margin-right: 0px !important;
    margin-bottom: 30px !important;
  }
  .heading {
    font-size: 1rem !important;
    padding-left: 15px !important;
  }
  .details {
    font-size: 0.7rem !important;
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 500px) {
  .center-box {
    margin-top: 110%;
    justify-content: space-evenly !important;
  }
  .square-box-main {
    height: 105px !important;
    text-align: left !important;
    width: 45% !important;
    justify-content: space-between !important;
    margin-right: 0px !important;
    margin-bottom: 15px !important;
  }
  .heading {
    font-size: 0.8rem !important;
    padding-left: 12px !important;
  }
  .details {
    font-size: 0.56rem !important;
    padding-left: 12px !important;
  }
}

@media only screen and (max-width: 420px) {
  .center-box {
    margin-top: 125%;
  }
  .square-box-main {
    height: 110px !important;
  } 
}
@media only screen and (max-width: 380px) {
  .center-box {
    margin-top: 130%;
  }
}
@media only screen and (max-width: 360px) {
  .center-box {
    margin-top: 136%;
  }
}
@media only screen and (max-width: 325px) {
  .center-box {
    margin-top: 144%;
  }
  .heading {
    font-size: 0.7rem !important;
    padding-left: 12px !important;
  }
  .details {
    font-size: 0.50rem !important;
    padding-left: 12px !important;
  }
}