.arrow-links{
    /* margin-left: 50px; */
    width: 20px;
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 20px; */

    margin-left: 50px;

}

.arrow-links Button{
    background-color: white;
    outline: none;
    border: none;
    /* margin-top: 15px; */
    color: black;
    margin: auto;

    transform: rotate(-90deg);
    /* margin-top: 5px; */

    font-size: small;

    font-weight: bolder;

    height: 20px;
    padding: 3px 6px 21px 3px;
    
    /* width: fit-content; */


}



/* 
.arrowLastLink{
    /* margin-left: 50px; */
/*    width: 20px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 50px;
    margin-left: 50px;


}

.arrowLastLink Button{


    background-color: white;
    outline: none;
    border: none;
    /* margin-top: 15px; */
  /*  color: black;
    margin: auto;

    transform: rotate(-90deg);
    /* margin-top: 5px; */

  /*  font-size: small;

    font-weight: bolder;


    height: 20px;
    width: fit-content;

}


 */
