.TrailerContainer {
  background-size: cover;
  background-color: teal;
  overflow: hidden;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

.Overlay {
  position: absolute !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Layer1 {
  height: 100vh;
  width: 100%;
}

.image-container {
  position: absolute !important;
  width: 32%;
  right: 0px !important;
  left: 68% !important;
  padding: 100px 100px !important;
}
.image-container img {
  width: 400px;
}
.trailer_3rdlayer {
  position: absolute !important;
  left: 180px !important;
  top: 100px !important;
  padding: 130px 30px !important;
  color: rgba(54, 65, 60, 0.938);
  font-weight: bolder !important;
  font-size: 2.6rem;
}

.textTitle {
  text-align: center;
  padding: 10px 20px;
}

.Layer4 {
  position: relative;
  width: 100%;
  padding: 20px 20px;
}
.playbtn {
  z-index: 20;
  position: absolute;
  left: 45%;
  top: 34%;
  cursor: pointer;
}
.playbtn img {
  width: 100px;
  height: 100px;
}
.Layer4img1 {
  position: relative;
  cursor: pointer;
  width: 540px;
}

.playbtn:hover {
  background-color: #07604898;
}

.image-animation-right {
  transition-timing-function: ease-in;
  transition: 0.8s;
  transform: translateX(-35%);
}
/* .image-animation-right-before {
  transition-timing-function: ease-out;
  transition: 0.8s;
  transform: translateX(0%);
} */
.image-animation-down {
  transition-timing-function: ease-in;
  transition: 0.8s;
  transform: translateY(-70%);
}
/* .image-animation-down-before {
  transition-timing-function: ease-out;
  transition: 0.8s;
  transform: translateY(0%);
} */

@media only screen and (max-width: 1580px) {
  .image-container {
    width: 32%;
    right: 0px !important;
    left: 68% !important;
    padding: 100px 100px !important;
  }
  .image-container img {
    width: 320px;
  }
  .Layer4 {
    padding: 0px 20px;
    margin-top: -10px;
  }
  .trailer_3rdlayer {
    left: 130px !important;
    top: 170px !important;
    padding: 10px 30px !important;
    font-size: 2rem;
  }
  .playbtn img {
    width: 70px;
    height: 70px;
  }
  .Layer4img1 {
    width: 390px;
  }
  .playbtn {
    left: 39%;
  }
}

@media only screen and (max-width: 800px) {
  .image-container {
    width: 32%;
    right: 0px !important;
    left: 62% !important;
    padding: 110px 100px !important;
  }
  .Layer1 {
    height: 100vh;
    width: 110%;
  }
  .image-container img {
    width: 280px;
  }
  .Layer4 {
    padding: 0px 20px;
    margin-top: -10px;
  }
  .trailer_3rdlayer {
    left: 50px !important;
    top: 200px !important;
    padding: 10px 20px !important;
    font-size: 1.6rem;
  }
  .Layer4img1 {
    width: 290px;
  }
  .playbtn img {
    width: 60px;
    height: 60px;
  }
  .playbtn {
    top: 30%;
    left: 41%;
  }
}

@media only screen and (max-width: 460px) {
  .Layer1 {
    margin-left: -40%;
    width: 180%;
  }
  .image-container {
    left: 25% !important;
    padding: 280px 100px !important;
  }
  .image-container img {
    width: 220px;
  }
  .Layer4 {
    padding: 0px 20px;
    margin-top: -10px;
  }
  .trailer_3rdlayer {
    left: 40px !important;
    top: 105px !important;
    padding: 10px 20px !important;
    font-size: 1.6rem;
  }
  .textTitle {
    text-align: center;
    padding: 8px 20px;
  }
  .Layer4img1 {
    width: 280px;
    margin-top: -20px;
  }
  .playbtn img {
    width: 55px;
    height: 55px;
  }
  .playbtn {
    top: 22%;
    left: 41%;
  }
}


@media only screen and (max-width: 400px) {
  .Layer1 {
    height: 100vh;
    margin-left: -30%;
    width: 180%;
  }
  .image-container {
    left: 26% !important;
    padding: 270px 100px !important;
  }
  .image-container img {
    width: 210px;
  }
  .Layer4 {
    padding: 0px 20px;
    margin-top: -10px;
  }
  .trailer_3rdlayer {
    left: 25px !important;
    top: 103px !important;
    padding: 10px 20px !important;
    font-size: 1.5rem;
  }
  .textTitle {
    text-align: center;
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 361px) {
  .image-container {
    left: 22% !important;
  }
  .image-container img {
    width: 200px;
  }
  .trailer_3rdlayer {
    left: 20px !important;
    top: 98px !important;
    padding: 10px 20px !important;
    font-size: 1.5rem;
  }
  .textTitle {
    padding: 8px 10px;
  }
  .Layer4img1 {
    width: 260px;
  }
  .playbtn img {
    width: 50px;
    height: 50px;
  }
  .playbtn {
    top: 20%;
  }
}
@media only screen and (max-width: 320px) {
  .image-container {
    left: 23% !important;
    padding: 235px 100px !important;
  }
  .image-container img {
    width: 180px;
  }
  .Layer4 {
    padding: 0px 20px;
    margin-top: -10px;
  }
  .trailer_3rdlayer {
    left: 15px !important;
    top: 92px !important;
    font-size: 1.4rem;
  }
  .textTitle {
    padding: 7px 20px;
  }
  .Layer4img1 {
    width: 248px;
  }
  .playbtn img {
    width: 50px;
    height: 50px;
  }
  .playbtn {
    top: 20%;
    left: 42%;
  }
}