.square-box-main {
  background-color: #4f1fb6ea;
  height: 190px;
  width: 20%;
  /* width: 20%;
    height: 200px; */
  color: white;

  font-family: bebas-neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 50px;
  transition: transform .5s;

}

.square-box-main:hover{
    transition: 0.2s;
    background-color: #b61f33;
    transform: scale(1.1);
}

.heading {
  /* background-color: blueviolet; */
  /* height: 70%; */
  font-weight: bolder;
  padding-left: 8%;
  padding-top: 10%;
  font-size: 20px;
}

.details {
  /* background-color: blue; */
  font-size: 12px;
  font-weight: bolder;
  padding-left: 8%;
  justify-content: center;
}


@media(max-width:1100px){
    .square-box-main {
        height: 200px;
        
    }      

}


@media(max-width:768px){
    .square-box-main {
        height: 120px;
        text-align: center;
        width: 25%;

        
    }      


    
.heading {
    /* background-color: blueviolet; */
    /* height: 70%; */
    padding-left: 5%;
    padding-top: 10%;
    font-size: 12px;
  }
  
  .details {
    /* background-color: blue; */
    font-size: small;
    font-weight: bolder;
    padding-left: 5%;
    justify-content: center;
    /* display: none; */
  }

}